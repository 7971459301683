 <template>
    <modal
        height="auto"
        :show="true"
        scrollable
        size="sm"
        @hide="$emit('hide')"
    >
        <div slot="header">
            <h2 class="font-normal">{{ name }} contact information</h2>
        </div>

        <div slot="body">
            <div v-if="loading" class="flex flex__justify-center w__100-p">
                <spinner />
            </div>

            <form v-else @submit.prevent="">
                <modal-field class="w-full mb-3" label="Company">
                    <input
                        v-model="company"
                        class="w-full"
                        type="text"
                    />
                </modal-field>

                <modal-field label="Country" class="w-full mb-3" >
                    <multiselect
                        v-model="country"
                        :options="countryList"
                        class="relative"
                        label="name"
                        track-by="code"
                    >   
                    </multiselect>
                </modal-field>

                <modal-field class="w-full mb-3" label="City">
                    <input
                        v-model="city"
                        class="w-full"
                        type="text"
                    />
                </modal-field>

                <modal-field class="w-full mb-3" label="Address">
                    <input
                        v-model="address"
                        class="w-full"
                        type="text"
                    />
                </modal-field>

                <modal-field class="w-full mb-3" label="Postcode">
                    <input
                        v-model="postcode"
                        class="w-full"
                        type="text"
                    />
                </modal-field>

                <modal-field class="w-full mb-3" label="Contact person">
                    <input
                        v-model="person"
                        class="w-full"
                        type="text"
                    />
                </modal-field>

                <modal-field class="w-full mb-3" label="E-mail">
                    <input
                        v-model="email"
                        class="w-full"
                        type="email"
                        :class="{'is-invalid': submitter && $v.email.$invalid}"
                    />
                </modal-field>

                <modal-field class="w-full" label="Phone">
                    <input
                        v-model="phone"
                        class="w-full"
                        type="text"
                    />
                </modal-field>
            </form>
        </div>

        <div slot="footer" class="w__100-p">
            <div class="flex justify-end w-full">
                <div class="flex">
                    <button class="px-10 btn-primary" @click="save">Save</button>
                    
                    <button class="px-8 ml-4 btn-grey-outline" @click="$emit('hide')">Cancel</button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import { email } from 'vuelidate/lib/validators'

import Spinner from '~/components/Spinner.vue';

export default {
    props: {
        name: {
            type: String,
        },

        value: {
            type: Object,
            default: () => { return {} },
        },
    },

    components: {
        Spinner
    },

    name: 'DetailShipmentItemModal',

    data() {
        return {
            person: '',
            email: '',
            phone: '',
            
            address: '',
            postcode: '',
            company: '',
            city: '',
            country: {},
            countryList: [],

            submitter: false,
            loading: true,
        }
    },

    computed: {
        key() {
            return this.addressModalKeyNameDictionary[this.name];
        },
    },

    methods: {
        save() {
            this.submitter = true;

            if (this.$v.$invalid) {
                return;
            }

            const result = {};

            result[`${this.key}_contactperson`] = this.person;
            result[`${this.key}_email`] = this.email;
            result[`${this.key}_phone`] = this.phone;
            result[`${this.key}_name`] = this.company;
            result[`${this.key}_city`] = this.city;
            result[`${this.key}_address`] = this.address;
            result[`${this.key}_countrycode`] = this.country.code;
            result[`${this.key}_postcode`] = this.postcode;

            this.$emit('save', result);
            this.$emit('hide');
        },
    },

    async created() {
        await this.$store.dispatch('countries/init');

        this.person = this.value[`${this.key}_contactperson`];
        this.email = this.value[`${this.key}_email`];
        this.phone = this.value[`${this.key}_phone`];

        this.company = this.value[`${this.key}_name`];
        this.city = this.value[`${this.key}_city`];
        this.address = this.value[`${this.key}_address`];
        this.postcode = this.value[`${this.key}_postcode`];

        this.countryList = this.$store.getters['countries/list'];
        this.country = this.countryList.find(country => country.code === this.value[`${this.key}_countrycode`]);

        this.loading = false;
    },

    validations: {
        email: {
            email,
        },
    }
}
</script>

<style lang="scss">
.multiselect__single {
    margin-left: 2px;
}
</style>