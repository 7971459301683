<template>
    <modal
        :adaptive="true"
        :show="true"
        height="auto"
        scrollable
        size="middle"
        @hide="$emit('setTimeHide')"
        class="modal-set-time"
    >
        <div slot="header">
            <h2 class="font-normal">
                Edit time
            </h2>
        </div>
        <div slot="body">
            <div v-for="(item, i, key) in values"  :key="key">
                <div v-if="item.show">
                    <div class="flex">
                        <modal-field class="mt-4 mb-4 pr-3" :label="item.label">
                            <date-picker
                                v-model="item.value"
                                :clearable="true"
                                :disable-past="false"
                                @input="inputDate(item, $event)"
                            >
                            </date-picker>
                        </modal-field>
    
                        
                        <template v-if="item.children">
                            <modal-field v-for="child in item.children" :key="child.key" bodyClass="flex flex__align-start mt-auto mb-2" class="my-4">
                                <template v-if="child.type === 'checkbox'">
                                    <checkbox v-model="child.value" :checked="child.value" />
                                    {{ child.label }}
                                </template>
                            </modal-field>
                        </template>
                    </div>

                    <div class="flex w-full">
                        <modal-field v-if="item.startTime" :label="item.endTime ? 'Starts at' : 'Time'" class="w-full" :class="{'pr-2': item.endTime}">
                            <div class="flex w-full">
                                <select
                                    v-model="item.startTime.value.hour"
                                    :disabled="!item.value"
                                >
                                    <option
                                        v-for="hour in hours"
                                        :key="'h' + hour"
                                        :value="hour"
                                    >
                                        {{ hour }}
                                    </option>
                                </select>

                                <select
                                    class="ml-2"
                                    v-model="item.startTime.value.minute"
                                    :disabled="!item.value"
                                >
                                    <option
                                        v-for="minute in minutes"
                                        :key="'m' + minute"
                                        :value="minute"
                                    >
                                        {{ minute }}
                                    </option>
                                </select>
                            </div>
                        </modal-field>

                        <modal-field v-if="item.endTime" label="Ends at" class="w-full pl-2">
                            <div class="flex w-full">
                                <select
                                    v-model="item.endTime.value.hour"
                                    :disabled="!item.value"
                                >
                                    <option
                                        v-for="hour in hours"
                                        :key="'h' + hour"
                                        :value="hour"
                                    >
                                        {{ hour }}
                                    </option>
                                </select>

                                <select
                                    class="ml-2"
                                    v-model="item.endTime.value.minute"
                                    :disabled="!item.value"
                                >
                                    <option
                                        v-for="minute in minutes"
                                        :key="'m' + minute"
                                        :value="minute"
                                    >
                                        {{ minute }}
                                    </option>
                                </select>
                            </div>
                        </modal-field>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer" class="w__100-p">
            <div class="flex justify-end w-full">
                <button
                    @click="$emit('setTimeHide')"
                    class="px-8 mr-4 outline-default"
                >Cancel</button>
                <button
                    :disabled="loading"
                    @click="save"
                    class="px-10 btn-primary"
                >Save</button>
            </div>
        </div>
    </modal>
</template>
<script>
import moment from 'moment'
import DatePicker from '~/components/DatePicker'
import Checkbox from '~/components/Checkbox';

const getDateFormatted = (date) => {
    return (
        `${date.getFullYear()}-${formatTwoDigits(date.getMonth() + 1)}-${formatTwoDigits(date.getDate())}`
        + ` ${formatTwoDigits(date.getHours())}:${formatTwoDigits(date.getMinutes())}:${formatTwoDigits(date.getSeconds())}`
    );
};

const formatTwoDigits = (value) => {
    return String(value).length < 2 ? `0${value}` : value;
};

export default {
    name: 'SetTimeModal',
    components: { DatePicker, Checkbox },
    props: {
        value: {
            type: [Number, String, Object],
            default: null,
        },
        consignment: {
            type: Object,
            default: () => { return {} },
        }
    },
    data() {
        return {
            loading: false,
            requested: '',
            actual: '',
            time: '',
            timeHour: '00',
            timeMinute: '00',
            confirmed: '',
            confirmedHour: '00',
            confirmedTime: '00',
            departure: '',
            modifiedFields: new Set(),
            values: {}
        }
    },
    methods: {
        inputDate(value, event) {
            if (event) {
                return;
            }
            
            value.startTime.value.hour = '';
            value.startTime.value.minute = '';

            if (value.endTime) {
                value.endTime.value.hour = '';
                value.endTime.value.minute = '';
            }
        },
        getHours(datetime) {
            if (!datetime) {
                return;
            }

            const date = new Date(datetime);

            return formatTwoDigits(date.getHours());
        },
        getMinutes(datetime) {
            if (!datetime) {
                return;
            }

            const date = new Date(datetime);

            return formatTwoDigits(date.getMinutes());
        },
        updateHours(item, value) {
            const date = new Date(this.value.values[item].value);
            date.setHours(value);

            this.value.values[item].value = date;
        },
        updateMinutes(item, value) {
            const date = new Date(this.value.values[item.key].value);
            date.setMinutes(value);

            this.value.values[item.key].value = getDateFormatted(date);
            this.modifiedFields.add(item.key);
        },
        async save() {
            this.loading = true;

            this.$emit('save', this.sendData);
            this.$emit('setTimeHide');

            this.loading = false;
        },
        init() {
            if (this.value.value === 'etd') {
                if (this.consignment.transportdates_pickupdate_initial) {
                    this.requested = moment(this.consignment.transportdates_pickupdate_initial);
                }

                if (this.consignment.transportdates_pickupdate) {
                    this.actual = moment(this.consignment.transportdates_pickupdate);
                }

                if (this.consignment.actual_departure_date) {
                    this.departure = moment(this.consignment.actual_departure_date)
                }

                return;
            }

            if (this.consignment.transportdates_deliverydate_initial) {
                this.requested = moment(this.consignment.transportdates_deliverydate_initial);
            }

            if (this.consignment.transportdates_deliverydate) {
                this.actual = moment(this.consignment.transportdates_deliverydate);
            }

            if (this.consignment.transportdates_etadate_initial) {
                this.confirmed = moment(this.consignment.transportdates_etadate_initial);
                this.confirmedHour = moment(this.consignment.transportdates_etadate_initial).format('HH') || '00';
                this.confirmedTime = moment(this.consignment.transportdates_etadate_initial).format('mm') || '00';
            }
        },
    },
    computed: {
        sendData() {
            const result = {};

            Object.values(this.values).forEach(item => {
                if (item.children) {
                    Object.values(item.children).forEach(child => {
                        if (child.type === 'checkbox') {
                            result[child.key] = child.value;
                        }
                    });
                }

                if (!item.value) {
                    result[item.key] = null;

                    if (item.dependency) {
                        result[item.dependency] = null;
                    }

                    if (item.endTime) {
                        result[item.endTime.key] = null;
                    }

                    return;
                }

                if (!item.startTime && !item.endTime) {
                    result[item.key] = moment(item.value).format('YYYY-MM-DD');
                    
                    if (item.dependency) {
                        result[item.dependency] = result[item.key];
                    }

                    return;
                }

                let date = moment(item.value);

                if (item.startTime.value.hour) {
                    date.set({h: +item.startTime.value.hour})
                }

                if (item.startTime.value.minute) {
                    date.set({m: +item.startTime.value.minute})
                }

                result[item.key] = date.format('YYYY-MM-DD HH:mm');

                if (item.dependency) {
                    result[item.dependency] = result[item.key];
                }

                if (!item.endTime) {
                    return;
                }

                let endDate = moment(item.value);

                if (item.endTime.value.hour) {
                    endDate.set({h: +item.endTime.value.hour})
                }

                if (item.endTime.value.minute) {
                    endDate.set({m: +item.endTime.value.minute})
                }

                result[item.endTime.key] = endDate.format('YYYY-MM-DD HH:mm');

                if (item.dependency) {
                    result[item.dependency] = result[item.key];
                }
            });

            return result;
        },
        titles() {
            if (this.value.value === 'etd') {
                return {
                    requested: 'Requested pickup date',
                }
            }

            return {
                actual: 'Actual delivery date',
            }
        },
        hours() {
            let hours = [];
            const zeroPad = (num, places) => String(num).padStart(places, '0')
            for (let index = 0; index < 24; index++) {
                let hour = index;
                hours.push(zeroPad(hour, 2));
            }
            return hours;
        },
        currentQuarter() {
            const zeroPad = (num, places) => String(num).padStart(places, '0')
            let now = moment().format('mm');
            now = parseInt(now);
            if (now > 0 && now < 15) {
                return 15;
            }
            if (now >= 15 && now < 30) {
                return 30;
            }
            if (now >= 30 && now < 45) {
                return 45;
            }
            return zeroPad(0, 2);
        },
        currentHour() {
            const zeroPad = (num, places) => String(num).padStart(places, '0')
            let now = moment().format('mm');
            let nowHour = moment().format('H');
            if (now > 50) {
                nowHour = parseInt(nowHour) + 1;
            }
            return zeroPad(nowHour, 2)
        },
        minutes() {
            let minutes = ['00', '15', '30', '45'];
            return minutes;
        },
    },
    created() {
        this.values = this.value.values;

        if (this.value.val) {
            this.time = moment(this.value.val)
            this.timeHour = moment(this.value.val).format('HH') || '00';
            this.timeMinute = moment(this.value.val).format('mm') || '00';
        }

        this.init();
    },
    watch: {
        confirmed(value) {
            if (!value) {
                this.confirmedHour = '00';
                this.confirmedTime = '00';
            }
        },
        time(value) {
            if (!value) {
                this.timeHour = '00';
                this.timeMinute = '00';
            }
        },
    }
}
</script>

<style lang="scss">
.modal-set-time {
    .modal__body {
        overflow: visible
    }

    select {
        border-radius: 4px;
    }
}
</style>
