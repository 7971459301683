<template>
    <div v-if="consignment" class="sh-d-comment">
        <div class="flex w__100-p pt-4 pb-4 flex__justify-between flex__align-center sh-d-comment__head">
            <div class="sh-d-progress__action f-w-bold f-size-16 flex flex__align-center cursor-pointer">
                Chat ({{ consignment.comments ? consignment.comments.length : 0 }})
            </div>
        </div>

        <div ref="commentContent" class="sh-d-comment__content mb-4">
            <div v-if="consignment.comments && consignment.comments.length" class="sh-d-progress__comments-body pb-1">
                <div v-for="item in consignment.comments" :key="item.id" class="sh-d-message mb-1 border-radius--input px-2 py-1" :class="[commentType(item).toLowerCase(), partnerComment(item)]">
                    <div class="flex flex__align-start">
                        <template v-if="isShared">
                            <div class="text-subheader">
                                {{ item.user.name }}
                            </div>
                        </template>
                        <template v-else>
                            <div class="text-subheader">
                                {{ commentUser(item.user) }}
                            </div>
                            <div class="ml-1">
                                ({{ item.user.organization.name }})
                            </div>
                        </template>

                        <div class="text-additional color-text-secondary flex flex__align-start">
                            <span class="ml-1 whitespace-no-wrap">
                                {{ prepareDateComment(item.created_at) }}
                            </span>

                            <span class="sh-d-comment__type comment__text-additional color-text-secondary ml-1">
                                {{ commentType(item) }}
                                </span>

                            <div v-if="item.user && item.user.id === user.id && adminRoute && !commentId" class="w-4 cursor-pointer ml-2 flex flex__justify-end" @click="editComment(item)" >
                                <svg-importer icon-name="icons/edit" height="16" />
                            </div>
                        </div>
                    </div>

                    <div class="text-body break-words">
                        {{ prepareTextComment(item.text, item.type) }}
                        <span v-if="item.changes && item.changes.length" class="ml-1 color-grey">(edited {{ prepareDateComment(item.changes[item.changes.length - 1].created_at) }})</span>
                    </div>
                </div>
            </div>

            <div v-else class="f-s-10 color-grey uppercase pb-3">No comments</div>
        </div>

        <form v-if="!isShared" @submit.prevent="sendComment">
            <div class="sh-d__comments-actions z-10 relative">
                <div class="flex flex__justify-between flex__align-center mt-3">
                    <div class="flex">
                        <radio-input v-if="!commentId && this.adminLevel" v-model="internal" input-value="EXTERNAL" input-name="internal" class="mr-8">
                            <span>Ext</span>
                        </radio-input>

                        <radio-input v-if="!commentId && !this.adminLevel" v-model="internal" input-value="QUESTION" input-name="internal" class="mr-8">
                            <span>Quest</span>
                        </radio-input>

                        <radio-input v-if="!commentId" v-model="internal" input-value="NOTE" input-name="internal" class="mr-8">
                            <span>Note</span>
                        </radio-input>
                    </div>
                </div>
            </div>

            <div class="mt-3 mb-2 relative icon-background border-radius">
                <div v-if="commentId" class="comments-edit-text text-additional color-text-secondary--dark pt-1 ml-2">
                    Editing a message
                </div>
                <div class="relative">
                    <textarea-autosize
                        v-model="comment"
                        placeholder="Add a comment"
                        class="input pr-16 textarea comments-textarea border-none border-radius-b-0 border-radius-t-0"
                        :class="{ active: comment.length, edit: commentId }"
                        :disabled="commentsLoading"
                    />
                    <div class="comments-send comments-buttons flex">
                        <spinner v-if="commentsLoading" size="16px" />

                        <div v-else class="flex">
                            <emoji-picker @emoji="appendEmoji" :search="search">
                                <div
                                    class="emoji-invoker"
                                    slot="emoji-invoker"
                                    slot-scope="{ events: { click: clickEvent } }"
                                    @click.stop="clickEvent"
                                >
                                    <svg-importer icon-name="icons/smile" key="chat-emoji" />
                                </div>
                                <div slot="emoji-picker" class="emoji-container" slot-scope="{ emojis, insert, display }">
                                    <div class="emoji-picker" :style="{ top: display.y + 'px', left: display.x + 'px' }">
                                    <div class="emoji-picker__search">
                                        <input type="text" v-model="search" v-focus>
                                    </div>
                                    <div>
                                        <div v-for="(emojiGroup, category) in emojis" :key="category">
                                            <h5>{{ category }}</h5>
                                            <div class="emojis">
                                                <span
                                                v-for="(emoji, emojiName) in emojiGroup"
                                                :key="emojiName"
                                                @click="insert(emoji)"
                                                :title="emojiName"
                                                >{{ emoji }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </emoji-picker>
                            <button v-if="!commentId" :disabled="commentsLoading" type="submit" class="ml-2">
                                <svg-importer icon-name="icons/send" key="chat-send" />
                            </button>
                            <button
                                v-if="commentId"
                                :disabled="commentsLoading"
                                type="button"
                                class="ml-2"
                                @click="fetchUpdateComment"
                            >
                                <svg-importer icon-name="icons/check" key="chat-confirm" />
                            </button>
                            <button
                                v-if="commentId"
                                :disabled="commentsLoading"
                                type="button"
                                class="ml-2"
                                @click="createComment"
                            >
                                <svg-importer icon-name="icons/close" key="chat-cancel" />
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
import axios from 'axios';
import moment from 'moment';

import Spinner from '~/components/Spinner';
import EmojiPicker from 'vue-emoji-picker';

export default {
    name: 'Chat',
    components: { Spinner, EmojiPicker },
    data() {
        return {
            search: '',
            consignment: null,
            commentId: null,
            commentsLoading: false,
            comment: '',
            internal: 'QUESTION',
        }
    },
    computed: {
        uuid() {
            return this.$route.params.uuid;
        },
        isShared() {
            return this.$route.name === 'shared.consignments.detail';
        },
        adminRoute() {
            return this.$route.name === 'admin.shipments.detail';
        },
        adminLevel() {
            return this.user?.level === 'super';
        },
        user() {
            return this.$store.getters['auth/user'];
        },
    },
    methods: {
        appendEmoji(emoji) {
            this.comment += emoji;
        },
        partnerComment(comment) {
            if (this.isShared) {
                return '';
            }

            return (
                comment.user.organization.key === this.consignment.owner_from_source
                || comment.user.organization.key === 'VCHAIN' 
                    ? ''
                    : 'partner'
            );
        },
        commentUser(user) {
            if (!user) {
                return 'User name';
            }

            if (!this.adminLevel && user?.level === 'super') {
                return 'vChain deviations support';
            }

            return user.name;
        },

        commentType(comment) {
            if (comment.type === 'QUESTION') {
                return 'Question';
            }

            if (comment.type === 'EXTERNAL') {
                return 'External';
            }

            if (comment.type === 'NOTE') {
                return 'Note';
            }

            return '';
        },

        prepareDateComment(value) {
            if (!value) {
                return '–';
            }

            return moment(value)
                .format('MMM DD, HH:mm');
        },

        prepareTextComment(value, type) {
            if (!value) {
                return '-';
            }

            if (type === 'INTERNAL' && value.toLowerCase().includes(':') && (value.toLowerCase().includes('snooze') || value.toLowerCase().includes('reminder'))) {
                return `${value} CET`;
            }

            return value;
        },

        scrollBottom() {
            if (!this.$refs.commentContent) {
                return;
            }

            this.$refs.commentContent.scrollTop = this.$refs.commentContent.scrollHeight;
        },

        async fetchConsignment() {
            const url = !this.isShared
                ? `${this.$apiUrl.consignments.base}/` + this.uuid + "?include=comments"
                : `/api/shared/consignments/${this.uuid}`;

            const { data } = await axios.get(url);

            this.consignment = data?.data;

            this.$nextTick(this.scrollBottom);
        },

        async fetchCreateComment() {
            if (!this.comment) {
                this.$snotify.warning('Comment required!');

                return
            }

            try {
                await axios.post(this.$apiUrl.comment, {
                    text: this.comment,
                    type: this.internal,
                    commentable_id: this.consignment.id,
                    commentable_type: 'App\\Models\\Consignment',
                });

                await this.fetchConsignment();

                this.comment = '';
            } catch(error) {
                this.$snotify.error(error.response.data.comment);
            }
        },

        async fetchUpdateComment() {
            if (!this.comment) {
                this.$snotify.warning('Comment required!');

                return
            }

            this.commentsLoading = true;


            try {
                await axios.put(`${this.$apiUrl.comment}/${this.commentId}`, {
                    text: this.comment,
                });

                await this.fetchConsignment();

                this.createComment();
            } catch(error) {
                this.$snotify.error(error.response.data.comment);
            }

            this.commentsLoading = false;
        },

        createComment() {
            this.commentId = null;
            this.comment = '';

            this.$nextTick(this.scrollBottom);
        },

        editComment(value) {
            this.comment = value.text;
            this.internal = value.internal;
            this.commentId = value.id;
            
            this.$nextTick(this.scrollBottom);
        },

        async sendComment() {
            this.commentsLoading = true;

            if (this.commentId) {
                await this.fetchUpdateComment();

                this.commentsLoading = false;

                return;
            }

            await this.fetchCreateComment();

            this.commentsLoading = false;
        },
    },
    created() {
        if (this.adminLevel) {
            this.internal = 'EXTERNAL';
        }

        this.fetchConsignment();
    },
}
</script>
<style lang="scss">
.comments-edit-text {
    // position: absolute;
    // top: -20px;
    // left: 0;
}

.comments-buttons {
    button:disabled {
        pointer-events: none;
        opacity: .4;
    }

    .emoji-invoker {
        position: static;
    }

    .emoji-picker {
        transform: translateY(-116%);
    }
}

.comments-send {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
}

.comments-textarea {
    max-height: 34px;
    line-height: 22px;
    background: transparent;
    padding-left: 8px;
    padding-right: 8px;

    // transition: all ease .2s;

    &.active, &:active, &:focus {
        background: transparent;
        max-height: 68px;
    }

    &.edit {
        padding-right: 104px;
    }
}
</style>