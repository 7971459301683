<template>
  <modal
    height="auto"
    :show="show"
    scrollable
    size="600"
    @hide="$emit('hide')"
  >
    <h2 slot="header">Document deletion</h2>

    <div v-if="file" slot="body">
      Are you sure you want to delete <strong>{{ file.filename }}</strong> file?
    </div>

    <div slot="footer" class="w__100-p border__top-grey-1 pt-6">
      <div class="flex justify-end w-full">
        <button class="px-8 btn-red mr-4" :class="{'btn-loading': loading}" @click="$emit('delete')">
          Delete file
        </button>

        <button @click="$emit('hide')" class="px-8 btn-grey-outline">
          Cancel
        </button>
      </div>
    </div>
  </modal>
</template>
<script>
export default {
  name: "ShipmentDocumentDelete",
  props: {
    show: Boolean,
    loading: Boolean,
    file: {
      type: Object,
      default: () => null,
    },
  },
};
</script>