<template>
    <modal :show="show" @hide="hide" size="lg" :bodyClass="'emissions-details-modal'" :loading="loading" :removeHeaderBottomMargin="true">
        <div slot="header" class="w-full relative" v-if="show">
            <h2>From {{ data.consignment.pickup_city }} to {{ data.consignment.delivery_city }}</h2>
            <router-link v-if="showGotoShipment && linkToShipment" :to="linkToShipment" class="absolute cursor-pointer" style="top: 6px; right:20px;">Go to shipment</router-link>
        </div>

        <div slot="body" v-if="show && loading === false">
            <div v-if="!emptyState" style="height: 40vh; outline: none;">
                <map-box
                    :zoom="0.1"
                    :center="[0,30]"
                    :layers="makeLayer()"
                    :map-options="{
                        logoPosition: 'bottom-right',
                        pitch: 50
                    }"
                    :fit-bounds="makeBounds()"
                    :on-map-load="onMapLoad"
                ></map-box>
            </div>
            <div v-else style="height: 40vh; outline: none;">
                <map-box
                    :zoom="0.1"
                    :center="[0,30]"
                    :map-options="{
                        logoPosition: 'bottom-right',
                        pitch: 50
                    }"
                    :on-map-load="onMapLoad"
                ></map-box>
            </div>

            <div v-if="!emptyState" class="flex w-full mt-6 mb-6">
                <div class="tabs w-full full-tabs">
                    <div class="tabs-header">
                        <h2>Emissions</h2>
                    </div>
                    <div class="tab" :class="{'active': activeTab === 'overview'}" @click="activeTab = 'overview'">Overview</div>
                    <div class="tab" :class="{'active': activeTab === 'details'}" @click="activeTab = 'details'">Details</div>
                </div>
            </div>
            <div v-else class="flex w-full mt-6 mb-6">
                <div class="tabs w-full full-tabs pb-6">
                    <div class="tabs-header without_tabs">
                        <h2>Emissions calculations was not successful, please contact support team in case of need</h2>
                    </div>
                </div>
            </div>

            <div v-if="activeTab === 'overview'" class="pb-6 px-6">
                <div class="flex flex__justify-between mb-6">
                    <div class="flex flex__align-end flex__wrap">
                        <div class="mr-10 mb-2">
                            <h3 class="text-brand-400">
                                {{data.co2e_kg | kpiUnit()}}
                                <span>
                                    <fa :icon="['fal','globe-europe']" class="text-gray-700" />
                                </span>
                                <div class="text-sm leading-tight text-gray-700 w-full" style="white-space:nowrap;">
                                    Total CO2e kg emitted
                                </div>
                            </h3>
                        </div>
                        <div class="mr-10 mb-2">
                            <h3 class="text-brand-400">
                                {{co2eKgToEurCompensation(data.co2e_kg) | kpiUnit()}}
                                <span>
                                    <fa :icon="['fal','euro-sign']" class="text-gray-700" />
                                </span>
                                <div class="text-sm leading-tight text-gray-700 w-full" style="white-space:nowrap;">
                                    Cost for compensation
                                </div>
                            </h3>
                        </div>

                        <div v-if="adminLevel && data.tariff_name" class="mr-10 mb-2">
                            <h3 class="text-brand-400">
                                {{data.tariff_name}}
                                <div class="text-sm leading-tight text-gray-700 w-full" style="white-space:nowrap;">
                                    Tariff
                                </div>
                            </h3>
                        </div>

                        <div v-if="data.co2_km_kg" class="mr-10 mb-2">
                            <h3 class="text-brand-400">
                                {{ data.co2_km_kg }}

                                <div class="text-sm leading-tight text-gray-700 w-full" style="white-space:nowrap;">
                                    CO2 Total (kg) per km, per kg of weight
                                </div>
                            </h3>
                        </div>
                    </div>

                    <div v-if="adminLevel" class="ml-4 mt-1 flex flex__column flex__align-end">
                        <div class="flex">
                             <multiselect
                                v-model="manualTag"
                                :options="['AIR', 'ECONOMY', 'EXPRESS', 'ROAD', 'OCEAN', 'RAIL']"
                                class="emissions-detail__manual-tag w-40"
                                tag-position="top"
                                placeholder="Manual tag"
                                style="min-width: 10rem;"
                                @input="updateManualTag"
                            >
                            </multiselect>
                            <fa v-if="manualLoading" class="h-5 w-5 text-xl fa-spin no-branding mt-2 ml-2" :icon="['fad','spinner-third']" />
                        </div>

                        <div v-if="emptyState" class="f-size-12-i text-right color-grey mt-1">Not calculated! <br> Select TAG and press Recalculate</div>
                    </div>
                </div>
                <strong>General information</strong>
                <div class="flex">
                    <div class="w-1/6">
                        <detail-modal-field title="Consignment no." :text="data.consignment.consignment_no" />
                    </div>
                    <div class="w-1/6">
                        <detail-modal-field title="Weight" :text="data.consignment.weight | unit('kg')" />
                    </div>
                    <div class="w-1/6">
                        <detail-modal-field title="Tag" :text="data.tag || ''" />
                    </div>
                    <div class="w-1/6">
                        <detail-modal-field title="Direction" :text="data.consignment.direction | transportTitle" />
                    </div>
                    <div class="w-1/6">
                        <detail-modal-field title="CO2 Total" :text="data.co2_total_kg | unit('kg')" />
                    </div>
                    <div class="w-1/6">
                        <detail-modal-field title="CO2e" :text="data.co2e_kg | unit('kg')" />
                    </div>
                </div>
                <div class="flex">
                    <div class="w-1/6">
                        <detail-modal-field title="From country" :text="data.consignment.pickup_countrycode" />
                    </div>
                    <div class="w-1/6">
                        <detail-modal-field title="From zip" :text="data.consignment.pickup_postcode" />
                    </div>
                    <div class="w-1/6">
                        <detail-modal-field title="From city" :text="data.consignment.pickup_city" />
                    </div>
                    <div class="w-1/6">
                        <detail-modal-field title="To country" :text="data.consignment.delivery_countrycode" />
                    </div>
                    <div class="w-1/6">
                        <detail-modal-field title="To zip" :text="data.consignment.delivery_postcode" />
                    </div>
                    <div class="w-1/6">
                        <detail-modal-field title="To city" :text="data.consignment.delivery_city" />
                    </div>
                </div>
            </div>
            <div v-if="activeTab === 'details'" class="pb-6">
                <table class="table w-full">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Route</th>
                            <th>CO<sub>2</sub>total</th>
                            <th>CO<sub>2</sub>fossil</th>
                            <th>CO<sub>2</sub>biogen</th>
                            <th>CO<sub>2</sub>e</th>
                            <th>SO<sub>2</sub></th>
                            <th>CO</th>
                            <th>HC</th>
                            <th>CH<sub>4</sub></th>
                            <th>NOx</th>
                            <th>N<sub>2</sub>O</th>
                            <th>PM</th>
                            <th>Energy</th>
                            <th>CO2/km/kg</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                            </td>
                            <td>
                                Total <small class="text-gray-700">({{ sumDistance() | unit('km') }})</small>
                            </td>
                            <td>{{ data.co2_total_kg | unit('kg') }}</td>
                            <td>{{ data.co2_fossil_kg | unit('kg') }}</td>
                            <td>{{ data.co2_biogen_kg | unit('kg') }}</td>
                            <td>{{ data.co2e_kg | unit('kg') }}</td>
                            <td>{{ data.so2_g | unit('g') }}</td>
                            <td>{{ data.co_g | unit('g') }}</td>
                            <td>{{ data.hc_g | unit('g') }}</td>
                            <td>{{ data.ch4_g | unit('g') }}</td>
                            <td>{{ data.nox_g | unit('g') }}</td>
                            <td>{{ data.n2o_g | unit('g') }}</td>
                            <td>{{ data.pm_g | unit('g') }}</td>
                            <td>{{ data.energy_mj | unit('MJ') }}</td>
                            <td>{{ data.co_km_kg | unit('') }}</td>
                        </tr>
                        <tr v-for="(cycle,i) of combineLegsAndCycles()" :key="i" :class="{'hidden': cycle.type !== 'header' && cycle.idx !== expandedLeg, 'clickable': cycle.type === 'header'}" @click="expandLeg(cycle.idx, cycle.type)">
                            <td>
                                <fa v-if="cycle.type === 'header' && expandedLeg !== cycle.idx" :icon="['fal','chevron-right']" transform="grow-6" fixed-width />
                                <fa v-if="cycle.type === 'header' && expandedLeg === cycle.idx" :icon="['fal','chevron-down']" transform="grow-6" fixed-width />
                            </td>
                            <td v-if="cycle.type === 'header'">
                                <div class="header">
                                    <span class="left mr-2">
                                        <fa :icon="['fal', getModalityIcon(cycle.modality)]" transform="grow-6" fixed-width />
                                    </span>
                                    <span class="title">From {{ getRouteLocation(cycle.calculationBasis.Route.Origin) }} to {{ getRouteLocation(cycle.calculationBasis.Route.Destination) }} <small class="text-gray-700">({{ cycle.calculationBasis.Route.Distance.Value | unit('km') }})</small></span>
                                </div>
                            </td>
                            <td v-if="cycle.type === 'sub'" class="indent-column">{{ getFuelStageTitle(cycle.data.FuelLifeCycleStage) }}</td>
                            <td>{{ cycle.data.CO2Total.Value | unit(cycle.data.CO2Total.Unit) }}</td>
                            <td>{{ cycle.data.CO2Fossil.Value | unit(cycle.data.CO2Fossil.Unit) }}</td>
                            <td>{{ cycle.data.CO2Biogen.Value | unit(cycle.data.CO2Biogen.Unit) }}</td>
                            <td>{{ cycle.data.CO2E.Value | unit(cycle.data.CO2E.Unit) }}</td>
                            <td>{{ cycle.data.SO2.Value | unit(cycle.data.SO2.Unit) }}</td>
                            <td>{{ cycle.data.CO.Value | unit(cycle.data.CO.Unit) }}</td>
                            <td>{{ cycle.data.HC.Value | unit(cycle.data.HC.Unit) }}</td>
                            <td>{{ cycle.data.CH4.Value | unit(cycle.data.CH4.Unit) }}</td>
                            <td>{{ cycle.data.NOx.Value | unit(cycle.data.NOx.Unit) }}</td>
                            <td>{{ cycle.data.N2O.Value | unit(cycle.data.N2O.Unit) }}</td>
                            <td>{{ cycle.data.PM.Value | unit(cycle.data.PM.Unit) }}</td>
                            <td>{{ cycle.data.Energy.Value | unit(cycle.data.Energy.Unit) }}</td>
                            <td>{{ toLegs(cycle) | unit('') }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </modal>
</template>

<style lang="scss" scoped>
    .alternatives {
        .item {
            .title.capitalize {
                text-transform: capitalize;
            }

            .bar {
                align-items: center;

                .bar-item {
                    position: relative;
                    height:.5em;

                    &:nth-child(1) {
                        background-color:rgb(58, 58, 58);
                    }

                    &:nth-child(2) {
                        background-color:#5e5e5e;
                    }

                    &:nth-child(3) {
                        background-color:rgb(165, 165, 165);
                    }

                    &:nth-child(4) {
                        background-color:rgb(201, 201, 201);
                    }

                    &:nth-child(5) {
                        background-color:rgb(216, 216, 216);
                    }

                    .description {
                        position: absolute;
                        display: block;
                        left:0;
                        margin-top:-.45em;
                        margin-left:100%;
                        padding-left: 1em;
                        white-space: nowrap;
                    }
                }
            }
        }

        .less {
            .difference {
                color : #20984B;
            }
            .bar-item {
                background-color : #20984B !important;
            }
        }

        .more {
            .difference {
                color : #E43C3C;
            }
            .bar-item {
                background-color : #E43C3C !important;
            }
        }
    }
    .accordion {
        display: flex;
        flex-direction: column;

        .item {
            flex-grow: 1;

            .header {
                display: flex;
                flex-direction: row;
                padding:.75em;
                cursor: pointer;
                border-bottom-width: 1px;

                .title {
                    flex-grow: 1;
                    font-weight: 500;
                }

                .left {
                    padding-right: 1em;
                }
                .right {
                    padding-left: 1em;
                }
            }
        }
    }

    .full-tabs {
        @apply flex justify-end relative;

        border-bottom-width: 2px;
        border-color: #DBDBDB;

        .tabs-header{
            @apply absolute;
            left: 0;
            padding-left: 1.75em;

            &.without_tabs {
                position: relative;
            }

            h2{
                font-weight: 500;
                color: #424242;
            }
        }

        &.tabs{
            margin-right: 0 !important;
        }

        .tab{
            @apply flex-initial;
            margin-bottom:-2px;
            padding: 1.25em;

            &.active {
                @apply border-b-2 border-gray-900 text-gray-900;
                font-weight: 600;
            }
        }
    }

    .indent-column{
        padding-left: 35px;
    }

    .clickable{
        cursor:pointer;
    }
</style>
<script>
    import axios from 'axios';
    import mapboxgl from "mapbox-gl";
    import * as turf from "@turf/turf";

    export default {
        name: 'emissionsDetailsDialog',

        props: {
            id: String,
            showGotoShipment: Boolean,
            propFrom: String,
            propTo: String,
        },

        filters: {
            unit(value, unit) {
                if(value === null)
                    return '';

                if (isNaN(value)) {
                    return '';
                }

                if (value == 0)
                    return '0 ' + unit;

                let precision = 100;
                if (value >= 10)
                    precision = 10;
                if (value >= 100)
                    precision = 1;

                let val = Math.round(value * precision) / precision;

                return val == 0
                    ? '< 0,01 ' + unit
                    : val.toString().replace('.',',') + ' ' + unit;
            },
            kpiUnit(value) {
                if(value === null)
                    return '–';

                if (isNaN(value)) {
                    return '–';
                }

                if (value == 0)
                    return '0';

                let precision = 100;
                if (value >= 10)
                    precision = 10
                if (value >= 100)
                    precision = 1

                let val = Math.round(value * precision) / precision;

                const format = num => {
                    const n = String(num),
                        p = n.indexOf('.')
                    return n.replace(
                        /\d(?=(?:\d{3})+(?:\.|$))/g,
                        (m, i) => p < 0 || i < p ? `${m},` : m
                    ).replace(',', ' ').replace('.', ',');
                }

                return format(val);
            },
            transportTitle(val) {
                if(!val)
                    return '';

                val = val.toLowerCase();
                val = val[0].toUpperCase() + val.slice(1);
                return val;
            }
        },

        watch: {
            id:{
                handler(){
                    if (this.id) {
                        this.fetch();
                    }
                    else {
                        this.show = false;
                    }
                }
            }
        },

        data: () => ({
            expandedLeg: null,
            loading: false,
            show: false,
            data: {},
            fuelLifeCycles: {
                'WellToTank': { order: 1, title: 'Well to tank'},
                'TankToWheel': { order: 2, title: 'Tank to wheel'},
                'WellToWheel': { order: 3, title: 'Well to wheel'},
                'WTW': { order: 4, title: 'Unknown'},
            },
            map: null,
            activeTab: 'overview',
            manualTag: null,
            emptyState: false,
            manualLoading: false,
        }),

        computed: {
            user() {
                return this.$store.getters['auth/user'];
            },
            adminLevel() {
                return this.user.level === 'super';
            },
            linkToShipment() {
                return `/transport/shipments/${this.id}`;
            }
        },

        methods: {
            getRouteLocation(data) {
                return data.City || data.LocationCode;
            },
            toLegs(value) {
                return value.data?.CO2KmKg?.Value;
            },
            onMapLoad(map){
                this.map = map;
            },
            hide() {
                this.show = false;
                this.$emit('hide');
            },
            async updateManualTag(value) {
                this.manualLoading = true;
                try {
                    await axios.patch(`${this.$apiUrl.emissions.shipment}/${this.data.consignment.uuid}/tag/${value}`)
                    await this.fetch(false);
                    this.$snotify.success('Successfully!')
                } catch(err) {
                    console.error(err?.response);
                } finally {
                    this.manualLoading = false;
                }
            },
            async fetch(startLoading = true){
                if (startLoading) {
                    this.loading = true;
                    this.$emit('loading', true);
                }

                try {
                    const res = await axios.get(`${this.$apiUrl.emissions.base}/` + this.id);

                    this.data = res.data.data;
                    this.expandedLeg = null;
                    this.manualTag = this.data.manual_tag || this.data.tag;
                    this.emptyState = false;
                } catch (err) {
                    this.data = { consignment: {} };
                    this.emptyState = true;
                    this.data.consignment.pickup_city = this.propFrom;
                    this.data.consignment.delivery_city = this.propTo;
                } finally {
                    this.loading = false;
                    this.show = true;
                    this.$emit('loading', false);
                }
            },
            getModalityIcon(mode) {
                switch (mode) {
                    case 'Road':
                        return 'truck';
                    case 'Ship':
                    case 'Sea':
                    case 'Ocean':
                        return 'ship';
                    case 'Air':
                        return 'plane';
                    case 'Rail':
                        return 'train';
                }
            },
            expandLeg(idx, type) {
                if(type !== 'header')
                    return;

                this.expandedLeg = this.expandedLeg !== idx ? idx : null;
            },
            getFuelStageTitle(key) {
                if(this.fuelLifeCycles[key])
                    return this.fuelLifeCycles[key].title;

                return 'Unknown';
            },
            getValueWithUnit(value, unit) {
                return this.$options.filters.unit(value, unit);
            },
            combineLegsAndCycles(){

                let combined = [];
                let idx = 0;
                this.data.legs.forEach(x => {
                    combined.push({
                        type: 'header',
                        data: x.Total,
                        modality: x.Modality,
                        calculationBasis: x.CalculationBasis,
                        idx: idx
                    });

                    let cycles = x.FuelLifeCycles.slice(); // copy cycles

                    cycles = cycles.sort((a,b) => {
                        let a_idx = this.fuelLifeCycles[a.FuelLifeCycleStage].order,
                            b_idx = this.fuelLifeCycles[b.FuelLifeCycleStage].order;
                        return a_idx > b_idx ? 1 : -1;
                    });

                    cycles.forEach(y => {
                        combined.push({
                            type: 'sub',
                            data: y,
                            idx: idx
                        });
                    });

                    combined.push({
                        type: 'sub',
                        data: x.Total,
                        idx: idx
                    });

                    idx++;
                });

                return combined;
            },
            sumDistance(){
                let distance = 0;
                this.data.legs.forEach(x => {
                    distance += x.CalculationBasis.Route.Distance.Value;
                });

                return distance;
            },
            gotoShipment(){
                this.show = false;
                this.$router.push({ name: 'transport.shipments.detail', params: { uuid: this.data.consignment.uuid } });
            },
            makeBounds() {
                let source = this.makeSource();

                if(source.data.features.length === 0 || !source.data.features[0]){
                    return {
                        options: {
                            padding: {
                                top: 0,
                                bottom: 50,
                                left: 100,
                                right: 100
                            }
                        }
                    };
                }

                let bounds = source.data.features[0].geometry.coordinates.reduce(function(bounds, coord) {
                    return bounds.extend(coord);
                }, new mapboxgl.LngLatBounds(source.data.features[0].geometry.coordinates[0], source.data.features[0].geometry.coordinates[0]));

                return {
                    bounds,
                    options: {
                        padding: {
                            top: 0,
                            bottom: 50,
                            left: 100,
                            right: 100
                        }
                    }
                };
            },

            co2eKgToEurCompensation(coe2Kg){
                // 18 USD per ton emitted co2e = 15.8839 EUR / ton = 0.0158839  / kg
                return coe2Kg * 0.0158839;
            },

            makeLayer() {
                let source = this.makeSource();

                return [
                    {
                        id: "route",
                        type: "line",
                        source: source,
                        layout: {
                            'line-join': 'round',
                            'line-cap': 'round'
                        },
                        paint: {
                            'line-color': this.$cookies.get('brand_color') || '#2E457C',
                            'line-width': 4
                        }
                    }
                ];
            },
            makeSource() {
                let points = [];

                this.data.legs.forEach(leg => {
                    if(leg.CalculationBasis && leg.CalculationBasis.Route && leg.CalculationBasis.Route.Waypoints && leg.CalculationBasis.Route.Waypoints.length > 0){
                        leg.CalculationBasis.Route.Waypoints.forEach(waypoint => {
                            points.push([waypoint.Longitude, waypoint.Latitude]);
                        });
                    }
                });

                let linestring;
                if(points.length > 0){
                    linestring = turf.lineString(points);
                }

                return {
                    type: "geojson",
                    data: {
                        type: "FeatureCollection",
                        features: [linestring]
                    }
                };
            }
        }
    }
</script>

<style lang="scss">
.emissions-detail__manual-tag .multiselect__content-wrapper {
    max-height: 155px !important;
}
</style>
