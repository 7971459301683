<template>
    <div class="sh-d-progress__item">
        <div class="flex flex__grow relative pl-8">
            <div class="sh-d-circle">
                <div class="sh-d-circle__circle"><span v-if="true"></span></div>
                <div class="sh-d-circle__line"></div>
            </div>

            <div class="w__100-p">
                <div class="sh-d-progress__content">
                    <div class="sh-d-progress__head z-10 relative">
                        <div>
                            <div class="sh-d-progress__action text-header-secondary flex flex__align-center">
                                {{ headName(value.events[0]) }}
                            </div>
                        </div>

                        <div class="ml-5 flex flex__align-center">
                            <div class="text-subheader text-right no-wrap">{{ prepareDate(value.events[0].time) }}</div>
                            <!--                            <div class="f-s-10 color-grey text-right letter-1-2 mt-1 sh-d-progress__head-text-min">{{ prepareTime(value.events[0].time) }} UTC</div>-->
                        </div>
                    </div>
                </div>

                <div class="sh-d-progress__body">
                    <group
                        v-for="(item, i) in groups"
                        :key="i"
                        :value="item"
                        :prev-value="groups[i-1] || null"
                        :adminRoute="adminLevel"
                        :permissions="permissions"
                        :prop_exception_details="exception_details"
                        :prop_exception_at="exception_at"
                        @save="$emit('save')"
                        @click="openEditEventModal"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import axios from 'axios';
import EmojiTextFields from '~/components/EmojiTextFields.vue'
import Group from './Group.vue';

export default {
    name: "detail",

    props: {
        index: {
            type: Number,
            default: 1,
        },
        value: {
            type: Object,
            default: () => { return {} },
        },
        permissions: {
            type: Object,
            default: () => { return {} },
        },
        exception_details: {
            type: Array,
            default: () => { return [] },
        },
        exception_at: {
            type: Array,
            default: () => { return [] },
        },
    },

    data() {
        return {
            showDetail: false,
            showComment: false,
            comment: '',
            internal: 0,
            events: [...this.value.events],
        }
    },

    computed: {
        user() {
            return this.$store.getters['auth/user'];
        },

        adminLevel() {
            return this.user?.level === 'super' || this.user?.level === 'site-admin';
        },

        groups() {
            return this.value.events.reduce((result, event, index) => {
                const lastGroup = result[result.length - 1];
                const isNewGroup = index !== 0
                    && lastGroup[0].name === event.name
                    && lastGroup[0].description === event.description
                    && lastGroup[0].location === event.location
                    && lastGroup[0].is_manual === event.is_manual
                    && moment(lastGroup[0].time).format('YYYY-MM-DD') === moment(event.time).format('YYYY-MM-DD');
                
                if (isNewGroup) {
                    lastGroup.push(event);
                } else {
                    result.push([event]);
                }

                return result;
            }, []);
        },

        template() {
            if (this.value.events[0].status.toLowerCase() === 'delivered') {
                return 3;
            }

            if (!this.value.xception) {
                return 1;
            }

            return 2;
        },

        typeTemplate() {
            if (!this.value.xception) {
                return '';
            }

            return this.value.xception.status === 'RESOLVED' ? 'success' : 'error';
        },
    },

    methods: {
        openEditEventModal(event) {
            this.$emit('click', event);
        },
        headName(value) {
            return value.group;
        },
        prepareDate(value) {
            if (!value) {
                return '–';
            }

            const day = moment(value).format('lll').split(' ')[0];

            return `${day}-${moment(value).format('DD-YYYY')}`
        },

        prepareFullDate(value) {
            if (!value) {
                return '–';
            }

            return moment(value).format('YYYY-MM-DD HH:mm:ss');
        },

        prepareTime(value) {
            if (!value) {
                return '-';
            }

            return moment(value).format('HH:mm')
        },

        prepareDateComment(value) {
            if (!value) {
                return '–';
            }

            return moment(value)
                .format('lll')
                .split(' ')
                .filter((item, i) => {
                    return i != 2;
                })
                .join(' ');
        },

        openDetail(item) {
            item.show = !item.show;
        },

        async resolve(id) {
            try {
                await axios.post(this.$apiUrl.exceptions.resolve, {
                    targets: [{ id }],
                });

                this.$emit('save')
            } catch(error) {
                console.error(error)
            }
        },

        async investigate(id) {
            try {
                await axios.post(this.$apiUrl.exceptions.claim, {
                    targets: [{ id }],
                });

                this.$emit('save')
            } catch(error) {
                console.error(error)
            }
        },

        async sendComment() {
            if (!this.comment) {
                this.$snotify.warning('Comment required!');

                return
            }

            try {
                await axios.post(this.$apiUrl.comment, {
                    text: this.comment,
                    internal: this.internal,
                    commentable_id: this.value.xception.id,
                    commentable_type: 'App\\Models\\Xception',
                });

                this.comment = '';

                this.$emit('save')
            } catch(error) {
                console.error(error)
            }
        }
    },

    metaInfo() {
        return { title: "Detail" };
    },

    components: {
        EmojiTextFields,
        Group,
    }
}
</script>

<style lang="scss">
@import '@/styles/components/_shipment-progress.scss';
@import '@/styles/components/_shipment-progress-elements.scss';

.sh-d {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 7.5rem);

    .modal__body {
        overflow: visible;
    }

    &__info {
        padding: 6px;
        border-radius: 32px;
        color: white;
        font-weight: bold;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-self: center;
        padding-right: 12px;

        svg {
            margin-right: 10px;
            height: 20px !important;
            width: 20px !important;
        }
    }

    &__body {
        display: flex;
        justify-content: space-between;
        flex-grow: 2;
        padding-top: 60px;
    }

    &__section {
        flex-grow: 2;

        &--s {
            width: 31%;
        }

        &--m {
            width: calc(51% - 20px);
            margin-left: 10px;
            margin-right: 10px;
        }

        &--e {
            width: 18%;
        }
    }

    &__timeline {
        min-width: 320px;
        max-width: 400px;
    }

    &__card {
        box-shadow: 0px 2px 25px rgba(27, 31, 46, 0.05);
        background-color: #fff;
        border-radius: 16px;
    }

    &__head,
    &__item {
        padding-top: 16px;
        padding-bottom: 16px;
    }

    &__head {
        // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    &__progress {
        flex: 1 1 auto;
        overflow-y: auto;
        height: 0px;
        overflow-x: hidden;
    }

    &__h-100 {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .icon-btn-blue svg path {
        fill: #00A3FF;
    }

    .icon-btn-green svg path {
        fill: #20984B;
    }

    .icon-btn-custom svg path {
        fill: rgba(0,0,0,0);
    }
}

.sh-d-comment {
    border-top: 1px solid rgba(0, 0, 0, 0.1);

    &__content {
        max-height: 225px;
        overflow-y: auto;
    }
}

.sh-d__section-values {
    display: flex;
    flex-wrap: wrap;

    .sh-d__section-item {
        width: 50%;
    }    
}

.sh-d__section-item {
    // width: 33.3%;
    // max-width: 33.3%;
    // min-width: 33.3%;

    .pl-0 {
        padding-left: 0 !important;
    }
}

.sh-d-additional{
    display: flex;
    justify-content: space-between;

    @apply pt-3 pl-5 pr-2 pb-2;

    &:not(:first-child) {
        @apply pl-2;
    }

    .doc-icon {
        svg {
            min-width: 20px;
        }

        .break-words {
            max-width: 214px;
        }
    }

    .border-none {
        border: 0 !important;
    }
}

.fade-modal-enter-active, .fade-modal-leave-active {
    transition: opacity .3s;
}
.fade-modal-enter, .fade-modal-leave-to {
    opacity: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.emoji-invoker {
  position: absolute;
  top: 0.4rem;
  right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s;
}
.emoji-invoker:hover {
  transform: scale(1.1);
}
.emoji-invoker > svg {
  fill: #b1c6d0;
}

.emoji-picker {
  position: absolute;
  z-index: 11;
  border: 1px solid #ccc;
  width: 15rem;
  height: 20rem;
  overflow: scroll;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  background: #fff;
  box-shadow: 1px 1px 8px #c7dbe6;
}
.emoji-picker__search {
  display: none;
}
.emoji-picker__search > input {
  flex: 1;
  border-radius: 10rem;
  border: 1px solid #ccc;
  padding: 0.5rem 1rem;
  outline: none;
}
.emoji-picker h5 {
  margin-bottom: 0;
  color: #b1b1b1;
  text-transform: uppercase;
  font-size: 0.8rem;
  cursor: default;
}
.emoji-picker .emojis {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.emoji-picker .emojis:after {
  content: "";
  flex: auto;
}
.emoji-picker .emojis span {
  padding: 0.2rem;
  cursor: pointer;
  border-radius: 5px;
}
.emoji-picker .emojis span:hover {
  background: #ececec;
  cursor: pointer;
}
.emoji-picker {
    position: absolute;
    right: 0 !important;
    top: 40px !important;
    left: auto !important;
}

.emoji-text-fields {
    input {
        border-top: 0;
        border-left: none !important;
        border-right: none !important;
        border-radius: 0px;
    }

    textarea {
        min-height: 40px;
    }
}

.sh-d-comment_no-active{
    .sh-d-message {
        &:last-child {
            margin-bottom: 0 !important;
        }
    }
}

.sh-d-comment__type {
    background: #fff;
    border-radius: 100px;
    padding: 0 4px;
}
</style>
