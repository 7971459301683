<template>
    <modal
        height="auto"
        :show="true"
        scrollable
        size="sm"
        @hide="$emit('hide')"
    >

        <div slot="header">
            <h2 class="font-normal">Owner</h2>
        </div>

        <div slot="body">
            <div v-if="loading" class="flex flex__justify-center w__100-p">
                <spinner />
            </div>

            <form v-else @submit.prevent="">
                <modal-field label="Organization" class="mb-3">
                    <multiselect
                        v-model="organization"
                        :options="organizations"
                        class="no-branding"
                        label="name"
                        track-by="id"
                        @input="site = null; sites = $event.sites"
                    >
                    </multiselect>
                </modal-field>

                <modal-field label="Site" class="mb-3">
                    <multiselect
                        v-model="site"
                        :options="sites"
                        label="name"
                        track-by="id"
                        class="no-branding"
                    >
                    </multiselect>
                </modal-field>
            </form>
        </div>
        
        <div slot="footer" class="w__100-p">
            <div class="flex justify-end w-full">
                <div class="flex">
                    <button class="px-10 btn-primary" :disabled="!organization || !site" @click="save">Save</button>
                    
                    <button class="px-8 ml-4 btn-grey-outline" @click="$emit('hide')">Cancel</button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import axios from 'axios';
import Spinner from '~/components/Spinner.vue';

export default {
    name: 'OwnerModal',

    components: {
        Spinner,
    },

    props: {
        value: {
            type: Object,
            default: () => { return {} },
        }
    },

    data() {
        return {
            loading: true,
            organization: null,
            site: null,

            organizations: [],
            sites: [],
        }
    },

    methods: {
        async fetchOrganizations() {
            const { data } = await axios.get(`${this.$apiUrl.organizations.active}?pageSize=1000&include=sites`);

            this.organizations = data.data;
        },

        async save() {
            if (!this.site || !this.organization) {
                return;
            }

            try {
                await axios.patch(`/api/consignments/${this.value.uuid}/owner`, {
                    site_id: this.site.id,
                    organization_id: this.organization.id,
                });

                this.$snotify.success(`Successfully`);
                this.$emit('hide');
                this.$emit('refreshTable');
            } catch(error) {
                this.$snotify.error(error?.response?.data?.message);
            }
        },

        init() {
            this.organization = this.organizations?.find(item => {
                return item.key === this.value?.owner_from_source;
            });

            this.sites = this.organization?.sites || [];

            this.site = this.sites?.find(item => {
                return item.key === this.value?.owner_site_from_source;
            }) || null;
        },
    },

    async created() {
        await this.fetchOrganizations();

        await this.init();

        this.loading = false;
    }
}
</script>
