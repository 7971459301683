<template>
    <div class="relative" :class="[className]">
        <button v-if="value.length > 1"  class="sh-d-progress__group-chevron" @click="setShow">
            <fa :class="{'rotate-180': show}" :icon="['fal','chevron-down']" />
        </button>

        <shipment-progress-sub-item
            v-if="value.length > 1 && !show"
            :value="parentGroup"
            :is-full-date-visible='isFullDateVisible'
            :adminRoute="adminLevel"
            :permissions="permissions"
            :prop_exception_details="prop_exception_details"
            :prop_exception_at="prop_exception_at"
            :groups="groups"
            :index="0"
            :class="[xceptionClassName(groups)]"
            @save="$emit('save')"
            @click="openEditEventModal"
        />

        <div v-if="show" class="sh-d-progress__group-container">
            <shipment-progress-sub-item
                v-for="(item, i) in value"
                :key="i"
                :value="item"
                :is-full-date-visible='isFullDateVisible'
                :adminRoute="adminLevel"
                :permissions="permissions"
                :prop_exception_details="prop_exception_details"
                :prop_exception_at="prop_exception_at"
                :class="[classNameItem(item)]"
                :groups="groups"
                :index="i"
                @save="$emit('save')"
                @click="openEditEventModal"
            />
        </div>
    </div>
</template>

<script>
import ShipmentProgressSubItem, { getXceptionGroupClass } from './SubItem.vue';
import moment from 'moment';

const STATUSES_PRIORITY = {
    'APPLIED': 1,
    'PENDING': 2,
    'NOT_APPLIED': 2,
    'IN_PROGRESS': 3,
    'UNDER_INVESTIGATION': 3,
    'RESOLVED': 4,
};

export default {
    props: {
        index: {
            type: Number,
            default: 1,
        },
        value: {
            type: Array,
            default: () => { return [] },
        },

        prevValue: {
            type: Array,
            default: () => { return [] },
        },

        permissions: {
            type: Object,
            default: () => { return {} },
        },

        prop_exception_details: {
            type: Array,
            default: () => { return [] },
        },

        prop_exception_at: {
            type: Array,
            default: () => { return [] },
        }
    },

    data() {
        return {
            show: this.value.length <= 1
        }
    },

    computed: {
        user() {
            return this.$store.getters['auth/user'];
        },

        adminLevel() {
            return this.user?.level === 'super' || this.user?.level === 'site-admin';
        },

        parentGroup() {
            const all = this.groups.flat();
            
            const APPLIED = this.findByStatus(all, 'APPLIED');
            const NOT_APPLIED = this.findByStatus(all, 'NOT_APPLIED');
            const UNDER_INVESTIGATION = this.findByStatus(all, 'UNDER_INVESTIGATION');
            const RESOLVED = this.findByStatus(all, 'RESOLVED');

            if (APPLIED) {
                return APPLIED;
            }

            if (NOT_APPLIED) {
                return NOT_APPLIED;
            }

            if (UNDER_INVESTIGATION) {
                return UNDER_INVESTIGATION;
            }

            if (RESOLVED) {
                return RESOLVED;
            }

            return this.value[0];
        },

        className() {
            const result = [];

            if (this.value.length > 1) {
                result.push('sh-d-progress__group');
            }

            if (this.value.length > 1 && !this.show && this.value.find(event => event.xceptions?.length)) {
                const xceptionStatuses = this.value.reduce((accum, event) => {
                    if (!event.xceptions?.length) {
                        return accum;
                    }

                    const newAccum = accum.concat(event.xceptions.map(xception => xception.status));

                    return newAccum;
                }, []);

                const groupStatus = Math.min(...xceptionStatuses.map(status => STATUSES_PRIORITY[status]));
                
                if (groupStatus) {
                    const res = Object.keys(STATUSES_PRIORITY).find(key => STATUSES_PRIORITY[key] === groupStatus);
                    result.push(res);
                }
            }

            if (this.value.length > 1 && this.value[this.value.length - 1].xceptions && this.show) {
                result.push('pb-0');
            }

            if (this.value.length > 1 && this.value[0].xceptions && !this.show) {
                result.push('group-first-xception-no-show');
            }

            if (this.value.length > 1 && this.value[0].xceptions && this.show) {
                result.push('group-first-xception-show');
            }

            if (this.value.length <= 1 && this.value[this.value.length - 1].xceptions) {
                result.push('one-xception')
            }

            return result;
        },

        groups() {
            return this.value.reduce((acc, current, index) => (
                (
                    index
                    && acc[acc.length - 1][0].xceptions
                    && current.xceptions
                    && acc[acc.length - 1][0].xceptions[0].status == current.xceptions[0].status
                    && acc[acc.length - 1][0].xceptions[0].type == current.xceptions[0].type
                )
                    || acc.push([]), acc[acc.length - 1].push(current), acc), []
            );
        },

        isFullDateVisible() {
            if (!this.prevValue) {
                return '';
            }

            const prev = this.prevValue[0];

            if (!prev) {
                return '';
            }

            if (!this.groups[0][0]) {
                return '';
            }

            const prevDate = moment(prev.time).format('YYYY-MM-DD');
            const currentDate = moment(this.groups[0][0].time).format('YYYY-MM-DD');


            if (prevDate != currentDate) {
                return this.prepareDate(this.groups[0][0].time);
            }

            return ''
        }
    },

    methods: {
        openEditEventModal(event) {
            this.$emit('click', event);
        },
        findByStatus(array, status) {
            return array.find(item => {
                return item?.xception?.status === status;
            });
        },

        setShow() {
            if (this.value.length <= 1) {
                return;
            }

            this.show = !this.show;
        },

        xceptionClassName(value) {
            if (!value?.length) {
                return '';
            }

            const className = value.reduce((accum, item) => {
                if (!item[0]?.xceptions?.length) {
                    return null;
                }
                
                const result = getXceptionGroupClass(item[0].xceptions, false);

                return result || accum;
            }, '');

            return className;
        },

        classNameItem(value) {
            const result = [];

            if (!value.xceptions) {
                result.push('mb-4');
            }

            return result;
        },

        prepareDate(value) {
            if (!value) {
                return '–';
            }

            const day = moment(value).format('lll').split(' ')[0];

            return `${day}-${moment(value).format('DD-YYYY')}`
        },
    },

    components: {
        ShipmentProgressSubItem,
    }
}
</script>

<style lang="scss">
@import '@/styles/components/_shipment-progress.scss';
@import '@/styles/components/_shipment-progress-group.scss';
</style>