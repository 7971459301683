<template>
    <div>
        <div class="sh-d__card sh-d__card--empty"> 
            <div v-for="statusName in statusNames" class="sh-d-progress__item">
                <div class="flex flex__grow">
                    <div class="sh-d-circle">
                        <div class="sh-d-circle__top sh-d-circle--empty"></div>
                        <div class="sh-d-circle__circle sh-d-circle--empty"><span></span></div>
                        <div class="sh-d-circle__line sh-d-circle--empty sh-d-circle__line--min"></div>
                        <div class="sh-d-circle__line sh-d-circle--empty"></div>
                    </div>

                    <div class="w__100-p">
                        <div class="sh-d-progress__content">
                            <div class="sh-d-progress__head z-10 relative">
                                <div>
                                    <div class="sh-d-progress__action f-w-bold f-size-18 flex flex__align-center">
                                        {{ statusName }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'EmptyTimeline',
    props: {
        statusNames: {
            type: Object,
            required: true,
        },
    },
};
</script>
