<template>
    <div class="sh-d-progress__subitem-wr" :class="[topClassName, { active: showDetail }]">
        <div v-if="isFullDateVisible && index === 0" class="pb-2 z-10">
            <div
                class="text-body text-right no-wrap"
                :class="{ 'color-text-secondary': isNotActual }"
            >
                <span v-if="isNotActual">Expected: </span>
                {{ isFullDateVisible }}
            </div>
        </div>

        <div class="flex flex__justify-between w-full">
            <div @click="showDetail = !showDetail" class="cursor-pointer">
                <div class="text-subheader">
                    <span v-if="'vessel' in value" :class="{ 'color-text-secondary': isNotActual }">
                        {{ value.status_from_source }}
                    </span>
                    <span v-else>
                        {{ value.name }}
                    </span>
                    <fa v-if="value.xceptions" :class="{'rotate-180': showDetail}" class="sh-d-progress__chevron ml-2" :icon="['fal','chevron-down']" />
                    <span v-show="value.xceptions && value.xceptions.length > 1" class="ml-2">(multiple)</span>
                </div>

                <router-link
                    v-if="adminRoute && value.carrier_status_id"
                    :to="`/admin/statuses-dictionary?filter-id=${value.carrier_status_id}`"
                    class="text-body color-brand mt-1"
                    target="_blank"
                >
                    {{ value.carrier_status_id || '–' }}
                </router-link>
                
                <div
                    v-if="'vessel' in value"
                    :class="{ 'color-text-secondary': isNotActual }"
                    class="text-body"
                >
                    <span>{{ value.description }}</span>
                </div>
                <div v-else class="text-body color-text-secondary">
                    <span v-if="value.manual_created_by && value.description">{{ value.description }}</span>
                    <span v-else-if="!value.manual_created_by && value.description_from_source">{{ value.description_from_source }}</span>

                    <fa v-if="(value.manual_created_by && adminRoute)" :icon="['fal','pen']" class="cursor-pointer ml-1" fixed-width  @click="openEventEditModal" />
                </div>

                <div v-if="value.event_reference" class="color-grey mt-1">
                    {{ value.event_reference }}
                </div>
                
                <div class="text-body color-text-secondary mt-1">
                    {{ getLocation(value.location) || 'No location' }}
                </div>
            </div>

            <div>
                <div class="text-body color-text-secondary letter-1-2 no-wrap ml-3 text-right">{{ prepareTime(value.time) }}</div>

                <div v-if="value.saved_by" class="text-body color-text-secondary mt-1 text-right">{{ value.saved_by }}</div>

                <div v-if="!isShared" class="flex flex__justify-end mt-2">
                    <button v-if="resolvedAll.length" :disabled="loading.resolvedAll" @click="resolve(resolvedAll)" class="btn-primary p-1 f-s-10">
                        Resolve all
                    </button>

                    <button v-if="investigateAll.length" :disabled="loading.investigateAll" @click="investigate(investigateAll)" class="btn-primary yellow p-1 f-s-10">
                        Investigate all
                    </button>
                </div>
            </div>
        </div>

        <div v-if="value.xceptions && showDetail" class="w__100-p pt-3">
            <div
                v-for="(xception, index) in value.xceptions"
                :key="`xception_${index}`"
                class="sh-d-progress__detail z-10 relative"
                :class="getClassName(xception)"
            >
                <div class="sh-d-progress__subitem pl-0">
                    <div class="sh-d-progress__desc">
                        <div class="text-body color-text-secondary">
                            Detail
                        </div>
                        <div class="f-size-14 leading-tight mt-1">{{ xception.humanized_detail || '–' }}</div>
                    </div>

                    <div v-if="!isShared" class="flex">
                        <button v-if="adminRoute && getIsShowResolve(xception)" :disabled="loading.resolved" class="px-10 btn-primary pl-2 pr-2" @click="resolve([xception.id])">
                            Resolve
                        </button>
                        <button v-if="adminRoute && getIsShowInvestigate(xception)" :disabled="loading.investigate" class="px-10 btn-primary yellow pl-2 pr-2 ml-2" @click="investigate([xception.id])">
                            Investigate
                        </button>
                    </div>
                </div>

                <div class="sh-d-progress__subitem">
                    <div class="sh-d-progress__half sh-d-progress__desc">
                        <div class="text-body color-text-secondary">Status</div>
                        <div class="text-subheader mt-1">{{ xception.humanized_status || '–' }}</div>
                    </div>

                    <div class="sh-d-progress__half sh-d-progress__desc">
                        <div class="text-body color-text-secondary">Type</div>
                        <div class="text-subheader mt-1">{{ xception.humanized_type || '–' }}</div>
                    </div>
                </div>

                <div class="sh-d-progress__subitem">
                    <div class="sh-d-progress__half sh-d-progress__desc">
                        <div class="text-body color-text-secondary uppercase">created</div>
                        <div class="text-subheader mt-1">{{ prepareFullDate(xception.created_at) }}</div>
                    </div>

                    <div v-if="adminRoute" class="sh-d-progress__half sh-d-progress__desc">
                        <div class="text-body color-text-secondary uppercase">Investigated by</div>
                        <div v-if="isShared">vChain CT</div>
                        <div v-else-if="!xception.investigator" class="text-subheader color-grey mt-1">Not investigated</div>
                        <div v-else class="text-subheader mt-1">{{ claimedBy(xception) }}</div>
                    </div>
                </div>

                <div class="sh-d-progress__subitem">
                    <div class="sh-d-progress__half sh-d-progress__desc">
                        <div class="text-body color-text-secondary uppercase">Resolved</div>
                        <div class="text-subheader mt-1">{{ prepareFullDate(xception.resolved_at) }}</div>
                    </div>

                    <div v-if="adminRoute" class="sh-d-progress__half sh-d-progress__desc">
                        <div class="text-body color-text-secondary uppercase">Resolved by</div>
                        <div class="text-subheader mt-1">{{ resolvedBy(xception) }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import axios from 'axios';

// import XceptionsEditModal from '~/pages/transport/modals/XceptionsEdit.vue';

const formatXceptionClass = (value, includeDetail) => {
    return includeDetail ? `detail ${value}` : value;
}

export const getXceptionGroupClass = (xceptions, includeDetail = false) => {
    if (!xceptions || !xceptions.length) {
        return '';
    }

    let match = xceptions
        .find(xception => 'APPLIED' === xception.status);

    if (match) {
        return formatXceptionClass('APPLIED');
    }

    match = xceptions
        .find(xception => ['IN_PROGRESS', 'UNDER_INVESTIGATION'].includes(xception.status));

    if (match) {
        return formatXceptionClass('IN_PROGRESS');
    }

    match = xceptions
        .find(xception => ['PENDING', 'NOT_APPLIED'].includes(xception.status));

    if (match) {
        return formatXceptionClass('PENDING');
    }

    return formatXceptionClass('RESOLVED');
};

const NO_PROGRESS_TYPE = 'NO_PROGRESS';
const STATUS_DICTIONARY = {
    applied: 'APPLIED',
    resolved: 'RESOLVED',
    underInvestigation: 'UNDER_INVESTIGATION',
    ongoingInvestigation: 'ONGOING_INVESTIGATION',
    inProgress: 'IN_PROGRESS',
};

export default {
    props: {
        value: {
            type: Object,
            default: () => { return {} },
        },

        isFullDateVisible: {
            type: String,
            default: () => { return '' },
        },

        permissions: {
            type: Object,
            default: () => { return {} },
        },

        prop_exception_details: {
            type: Array,
            default: () => { return [] },
        },

        prop_exception_at: {
            type: Array,
            default: () => { return [] },
        },

        groups: {
            type: Array,
            default: () => { return [] },
        },

        index: {
            type: Number,
            default: null,
        },
    },

    data() {
        return {
            showDetail: false,
            showExceptionModal: false,
            loading: {
                resolvedAll: false,
                investigateAll: false,
                resolved: false,
                investigate: false,
            },
        }
    },

    computed: {
        isShared() {
            return this.$route.name === 'shared.consignments.detail';
        },
        isNotActual() {
            return 'actual' in this.value && !this.value.actual;
        },
        topClassName() {
            return getXceptionGroupClass(this.value.xceptions, true);
        },

        user() {
            return this.$store.getters['auth/user'];
        },

        adminRoute() {
            return this.user?.level === 'super';
        },

        actionAll() {
            const filter = this.groups.filter(item => {
                return item.length > 1;
            });

            let findIndexGroup = null;

            filter.forEach((item, i) => {
                if (item[0].id === this.value.id) {
                    findIndexGroup = i
                }

                return;
            });

            if (findIndexGroup === null) {
                return [];
            }

            return filter[findIndexGroup].map(item => { return item.xception });
        },

        investigateAll() {
            if (!this.actionAll.length) {
                return [];
            }

            return this.actionAll
                .filter(item => {
                    return this.adminRoute && item?.status === STATUS_DICTIONARY.applied || (item?.type === NO_PROGRESS_TYPE && item?.status !== STATUS_DICTIONARY.resolved);
                })
                .map(item => {
                    return item.id;
                });
        },

        resolvedAll() {
            if (!this.actionAll.length) {
                return [];
            }

            return this.actionAll
                .filter(item => {
                    return this.adminRoute && item?.status === STATUS_DICTIONARY.underInvestigation && item.type !== NO_PROGRESS_TYPE;
                })
                .map(item => {
                    return item.id;
                });
        }
    },

    methods: {
        getLocation(location) {
            if (!location || typeof location === 'string') {
                return location;
            }

            return location.name;
        },
        openEventEditModal() {
            this.$emit('click', this.value);
        },
        getIsShowResolveAndInvestigate(xception) {
            return !(xception.status === STATUS_DICTIONARY.underInvestigation && xception.type === NO_PROGRESS_TYPE);
        },
        getIsShowInvestigate(xception) {
            return ['NOT_APPLIED', 'APPLIED'].includes(xception.status) && this.getIsShowResolveAndInvestigate(xception);
        },
        getIsShowResolve(xception) {
            return xception.status === STATUS_DICTIONARY.underInvestigation || xception.status === STATUS_DICTIONARY.ongoingInvestigation && this.getIsShowResolveAndInvestigate(xception);
        },
        getClassName(xception) {
            if (!xception) {
                return ''
            }

            if (xception.status === STATUS_DICTIONARY.inProgress) {
                return 'detail IN_PROGRESS';
            }

            return `detail ${xception.status}`;
        },
        prepareExceptionDetails(value) {
            if (!value) {
                return '–';
            }

            if (!value?.exception_details) {
                return '–';
            }

            return value.exception_details;
        },

        prepareDate(value) {
            if (!value) {
                return '–';
            }

            const day = moment(value).format('lll').split(' ')[0];

            return `${day}-${moment(value).format('DD-YYYY')}`
        },

        prepareFullDate(value) {
            if (!value) {
                return '–';
            }

            return moment(value).format('YYYY-MM-DD HH:mm:ss');
        },

        prepareTime(value) {
            if (!value) {
                return '-';
            }

            return moment(value).format('HH:mm')
        },

        claimedBy(value) {
            return value?.investigator?.name || '–';
        },

        resolvedBy(value) {
            return value?.resolver?.name || '–';
        },

        async resolve(ids) {
            if (ids.length > 1) {
                this.loading.resolvedAll = true;
            } 

            if (ids.length === 1) {
                this.loading.resolved = true;
            }

            try {
                await axios.post(`${this.$apiUrl.exceptions.resolve}`, { ids });

                this.$emit('save')
            } catch(error) {
                console.error(error)
            } finally {
                if (ids.length > 1) {
                    this.loading.resolvedAll = false;
                } 

                if (ids.length === 1) {
                    this.loading.resolved = false;
                }
            }
        },

        async investigate(ids) {
            if (ids.length > 1) {
                this.loading.investigateAll = true;
            } 

            if (ids.length === 1) {
                this.loading.investigate = true;
            }

            try {
                await axios.post(`${this.$apiUrl.exceptions.claim}`, { ids });

                this.$emit('save')
            } catch(error) {
                console.error(error)
            } finally {
                if (ids.length > 1) {
                    this.loading.investigateAll = false;
                } 

                if (ids.length === 1) {
                    this.loading.investigate = false;
                }
            }
        },
    },
}
</script>
